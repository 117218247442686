import React, { useCallback, useContext, useState } from 'react'
import { createSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import TextLink from '@divvy-web/skylab.textlink'
import { bool } from 'prop-types'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Spinner from '@divvy-web/skylab.spinner'
import SelectedDecision from '../../gql/mutations/SelectedDecision.gql'
import { PATHNAME_CONSTS } from '../../../resources/constants'
import CustomerAssistanceButton from '../../../components/CustomerAssistanceButton/CustomerAssistanceButton'
import OneColorSidebar from '../../../components/OneColorSidebar/OneColorSidebar'
import ImageWrapper from '../../../components/ImageWrapper'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { DecisionContext } from '../DecisionsContext'
import ApplicationAgreements from '../../../components/ApplicationAgreements'
import { GetCreditApplicationTilaInfoDocument } from '../../gql/GetCreditApplicationTilaInfo.gql'
import { getSideBarImage } from '../applicationProcessedUtils'
import { logError, logInfo } from '../../../utils/loggerUtils'
import { securedCreditLineOfferAcceptanceCss } from './securedCreditLineOfferAcceptanceStyles'

const SecuredCreditLineOfferAcceptance = () => {
  const navigate = useNavigate()
  const { appId } = useParams()
  const { pathname } = useLocation()
  const { isMobile } = useDeviceDetect()
  const showToast = useToast()
  const [getClassName, makeTestId] = useNameFormatter('SecuredCreditLineOfferAcceptance')
  // TODO: convert mutation to graphql and use typed mutation version here
  const [selectedDecision, { loading: isSelectedDecisionLoading }] = useMutation(SelectedDecision)
  const { securedLineOffer, isLoggedInUserAuthorizedSigner, authSignerEmail } = useContext(DecisionContext)
  const { id: decisionId, offerType } = securedLineOffer || {}

  const isSingleOffer = !pathname.includes('credit-line-offers')

  // for ApplicationAgreements
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const { data, loading: isLoadingTilaInfo } = useQuery(GetCreditApplicationTilaInfoDocument, {
    fetchPolicy: 'network-only',
    variables: {
      creditApplicationId: appId,
      underwritingDecisionUuid: decisionId,
      underwritingOfferType: offerType,
      isAuthSigner: isLoggedInUserAuthorizedSigner,
    },
    skip: !securedLineOffer,
  })

  const creditApplication = data?.creditApplication || {}
  const { tila, appVersion } = creditApplication
  const isAppVersion2 = appVersion === 2
  const showAppVersion2 = !isLoadingTilaInfo && isAppVersion2 && data
  const hasAgreedToOfferSummary = !!tila?.meta?.signedAt
  const needsToSignTila = tila && !hasAgreedToOfferSummary
  const isLoading = isLoadingTilaInfo || isSelectedDecisionLoading
  const isAcceptOfferButtonDisabled = isAppVersion2 && (!hasAgreedToTerms || isLoading || needsToSignTila)

  const handleCheckboxClick = () => {
    setHasAgreedToTerms(!hasAgreedToTerms)
  }
  // end for ApplicationAgreements

  const handleAcceptOfferNavigate = useCallback(() => {
    navigate({
      pathname: `/app/${appId}/section/processing-application`,
      search: createSearchParams({
        pollingForStatusSince: new Date().getTime(),
        isPollingAfterOfferAcceptance: true,
      }).toString(),
    })
  }, [appId, navigate])

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId,
      },
      onCompleted: () => {
        logInfo({
          attributes: {
            action: 'selectedDecision',
            result:
              'accepts offer for secured credit line and send to polling (/app/${appId}/section/processing-application)',
          },
          eventName: 'SecuredCreditLineOfferAcceptance',
        })

        /* toast and navigation to dashboard handled in RocketAnimation for appVersion === 2 */
        appVersion === 2 ? handleAcceptOfferNavigate() : navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
      },
      onError: (e) => {
        logError({
          attributes: {
            action: 'selectedDecision',
            message: e?.message,
            result: 'Error accepting offer for secured credit line',
          },
          eventName: 'SecuredCreditLineOfferAcceptance',
        })

        showToast(
          TOAST_TYPE_DANGER,
          <FormattedMessage
            defaultMessage='There was an error accepting your offer.'
            id='sputnik.SecuredCreditLineOfferAcceptance__oRIVFo'
          />,
          {
            autoHideDelay: 5000,
          },
        )
      },
    })
  }

  const handlePreviousClick = () => {
    if (isSingleOffer) {
      navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
    } else {
      navigate(-1)
    }
  }

  if (isLoadingTilaInfo) {
    return (
      <div css={securedCreditLineOfferAcceptanceCss}>
        <Spinner centered />
      </div>
    )
  }

  return (
    <div css={securedCreditLineOfferAcceptanceCss}>
      <CustomerAssistanceButton shouldUseSpecialMobileStyles />
      <div className='main-container fs-unmask'>
        <main className={getClassName('page-content')}>
          <div
            className={getClassName('body')}
            data-testid={makeTestId('')}
          >
            <p className={getClassName('title')}>
              <FormattedMessage
                defaultMessage='Your secured credit line'
                id='sputnik.SecuredCreditLineOfferAcceptance__BYoPgD'
              />
            </p>
            {isMobile && (
              <div>
                <ImageWrapper
                  alt='person scanning mobile device'
                  className={getClassName('mobile-secured-offer-image')}
                  imageName='credit-line-offer-mobile'
                />
              </div>
            )}
            <p className={getClassName('how-it-works')}>
              <FormattedMessage
                defaultMessage='How it works:'
                id='sputnik.SecuredCreditLineOfferAcceptance__ON3IdR'
              />
            </p>
            <div className={getClassName('how-it-works-list')}>
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage='Your credit limit is funded by a cash security deposit, which is adjustable and refundable based on your balance.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__STt4ih'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Your balance is due in full at the end of each billing cycle.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__8LBAFn'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Make payments using your cash security deposit or another funding source.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__nKd0hF'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Earn 1.5% cash back rewards on all eligible purchases.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__o2g3F3'
                  />
                </li>
              </ul>
            </div>
            <p className={getClassName('learn-more-wrapper')}>
              <FormattedMessage
                defaultMessage='Still need more info? <textlink>Learn more about secured credit lines</textlink>'
                id='sputnik.SecuredCreditLineOfferAcceptance__N5sAdA'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://help.getdivvy.com'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </p>
            {showAppVersion2 && isLoggedInUserAuthorizedSigner && (
              <ApplicationAgreements
                creditApplication={creditApplication}
                decisionId={decisionId}
                hasAgreedToOfferSummary={hasAgreedToOfferSummary}
                hasAgreedToTerms={hasAgreedToTerms}
                offerType={offerType}
                onCheckboxClick={handleCheckboxClick}
              />
            )}
            {showAppVersion2 && !isLoggedInUserAuthorizedSigner && (
              <div className={getClassName('unauthorized-accept-message')}>
                <FormattedMessage
                  defaultMessage='Only the authorized signer ({email}) can accept this offer.'
                  id='sputnik.SecuredCreditLineOfferAcceptance__igv+K/'
                  values={{
                    email: authSignerEmail,
                  }}
                />
              </div>
            )}
            {!showAppVersion2 && (
              <p className={getClassName('review-disclosure')}>
                <FormattedMessage
                  defaultMessage="By accepting this offer, we'll conduct the final review of your credit application and email you with our approval decision in 3-5 business days."
                  id='sputnik.SecuredCreditLineOfferAcceptance__0yOB2W'
                />
              </p>
            )}
            <section className={getClassName('buttons')}>
              <BasicButton
                className={getClassName('previous')}
                dataTestId={makeTestId('previous')}
                type={BASIC_BUTTON_TYPE_FLAT}
                onClick={handlePreviousClick}
              >
                <FormattedMessage
                  defaultMessage={`
                      {isSingleOffer, select,
                        true {Return to dashboard}
                        other {Previous}
                      }
                    `}
                  id='sputnik.StandardCreditLineOfferAcceptance__JJNc3c'
                  values={{
                    isSingleOffer,
                  }}
                />
              </BasicButton>
              {isLoggedInUserAuthorizedSigner && (
                <BasicButton
                  className={getClassName('accept')}
                  dataTestId={makeTestId('accept')}
                  disabled={isAcceptOfferButtonDisabled}
                  showSpinner={isSelectedDecisionLoading}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.SecuredCreditLineOfferAcceptance__/xro5W'
                  />
                </BasicButton>
              )}
            </section>
          </div>
        </main>
      </div>
      {!isMobile && <OneColorSidebar srcImage={getSideBarImage()} />}
    </div>
  )
}

export default SecuredCreditLineOfferAcceptance
