import { replaceBreakableSpaces } from '../components/utils'

export const ADDRESS_DEBOUNCE_TIME = 150
export const INDUSTRY_DEBOUNCE_TIME = 250
export const MX_INSTITUTION_DEBOUNCE_TIME = 250

export const MIGRATION = 'migration'
export const MAJOR_ACCOUNT_CHANGE = 'major_account_change'

// Keys used in local storage
export const DEAL_SOURCE_ID_KEY = 'deal_source_id'
export const AUTH_STORAGE_TOKEN_KEY = 'logged_in_user_token'
export const APPLICANT_EMAIL_KEY = 'applicant_email_key'
export const APPLICANT_AUTH_ID = 'applicant_auth_id'
export const SALESFORCE_CREDIT_IDS = 'salesforce_credit_ids'
export const LOCAL_STORAGE_KEYS = {
  APPLICANT_AUTH_ID,
  APPLICANT_EMAIL_KEY,
  AUTH_STORAGE_TOKEN_KEY,
  DEAL_SOURCE_ID_KEY,
  SALESFORCE_CREDIT_IDS,
}
export const BDC_MODAL_VIEWED_KEY = 'bdc_modal_viewed'

export const PATHNAME_CONSTS = {
  RIGHT_FIT_SURVEY_PATH: '/right-fit-survey',
  AUTH_PATH: '/auth',
  MIGRATION_PATH: '/migration',
  GOOD_FIT_PATH: '/good-fit',
  NOT_GOOD_FIT_PATH: '/not-good-fit',
  VERIFY_EMAIL_PATH: '/verify-email',
  VERIFY_PASSWORD_PATH: '/verify-password',
  VERIFY_CODE_PATH: '/verify-code',
  MOBILE_DOCUMENT_UPLOAD_PATH: '/mobile-document-upload',
  SIGN_UP_PATH: '/signup',
  DASHBOARD_PATH: '/dashboard',
  MULTI_CREDIT_LINE_OFFER_PATH: '/credit-line-offers',
  MULTI_OFFER_TO_STANDARD_OFFER_PATH: '/credit-line-offers/standard-offer',
  MULTI_OFFER_TO_VARIABLE_OFFER_PATH: '/credit-line-offers/variable-offer',
  MULTI_OFFER_TO_SECURED_OFFER_PATH: '/credit-line-offers/secured-offer',
  SINGLE_CREDIT_LINE_OFFER_PATH: '/credit-line-offer',
  MANUAL_REVIEW_PATH: '/working-out-details',
  DUAL_OFFER_PATH: '/dual-offer',
  PRE_APPROVED_PATH: '/pre-approved',
  FROZEN_CREDIT_PATH: '/frozen-credit',
  DECLINED_PATH: '/declined',
  STANDARD_OFFER_PATH: 'standard-offer',
  VARIABLE_OFFER_PATH: 'variable-offer',
  SECURED_OFFER_PATH: 'secured-offer',
}

/*
 * Terms and services link all go to the same URL until we get the updated pdfs
 * J&S is the only thing currently supported for new customers
 */
export const URL = {
  APP_PROD: 'apply.divvy.co',
  BILL_PRIVACY_NOTICE: 'https://www.bill.com/privacy',
  BILL_REWARDS_LINK: 'http://bill.com/product/rewards',
  BILL_REWARDS_TERMS: 'https://www.bill.com/legal/bill-rewards-program',
  BILL_TERMS_AND_CONDITIONS: 'https://www.bill.com/legal/spend-expense-terms-of-service',
  CREDIT_IMPACT_LEARN_MORE: 'https://help.getdivvy.com/en/articles/5043115-divvy-credit-application-faq#h_8ce5a44308',
  DIVVY_PROD: 'https://app.divvy.co',
  TERMS_AND_SERVICES_BUS: 'https://app.divvy.co/assets/pdfs/divvy-signup-application-wex-business-only.pdf',
  TERMS_AND_SERVICES_CRB_BUS: 'https://app.divvy.co/assets/pdfs/bill-divvy-signup-application-dsa_crb_bo.pdf',
  TERMS_AND_SERVICES_CRB_JS: 'https://app.divvy.co/assets/pdfs/bill-divvy-signup-application-dsa_crb_js.pdf',
  TERMS_AND_SERVICES_JS: 'https://app.divvy.co/assets/pdfs/divvy-signup-application-wex-js.pdf',
}

export const CreditApplicationStatus = {
  ACCOUNT_READY: 'ACCOUNT_READY',
  APPROVED: 'APPROVED',
  AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
  BUILD_ACCOUNT: 'BUILD_ACCOUNT',
  CLOSED_AS_DUPLICATE: 'CLOSED_AS_DUPLICATE',
  CLOSED_LOST: 'CLOSED_LOST',
  COMPANY_BUILT: 'COMPANY_BUILT',
  CORRECTION_REQUESTED: 'CORRECTION_REQUESTED',
  CUSTOMER_CANCELED: 'CUSTOMER_CANCELED',
  EXPIRED: 'EXPIRED',
  FROZEN_CREDIT: 'FROZEN_CREDIT',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  MORE_INFO_NEEDED: 'MORE_INFO_NEEDED',
  NOT_STARTED: 'NOT_STARTED',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  OFFER_DECLINED: 'OFFER_DECLINED',
  REJECTED: 'REJECTED',
  REVIEW_OFFER: 'REVIEW_OFFER',
  SUBMITTED: 'SUBMITTED',
  VALIDATION_IN_PROGRESS: 'VALIDATION_IN_PROGRESS',
  VALIDATION_IN_REVIEW: 'VALIDATION_IN_REVIEW',
  VALIDATION_NOT_STARTED: 'VALIDATION_NOT_STARTED',
  VALIDATION_SUCCESSFUL: 'VALIDATION_SUCCESSFUL',
  MIGRATION_COMPLETE: 'MIGRATION_COMPLETE',
}

export const EDITABLE_STATUSES = ['AWAITING_SIGNATURE', 'IN_PROGRESS', 'CORRECTION_REQUESTED', 'NOT_STARTED']

export const LEGAL_BUSINESS_NAME = 'LEGAL_BUSINESS_NAME'
export const TAX_ID = 'TAX_ID'
export const AUTHORIZED_SIGNER = 'AUTHORIZED_SIGNER'
export const BENEFICIAL_OWNER_0 = 'BENEFICIAL_OWNER_0'
export const BENEFICIAL_OWNER_1 = 'BENEFICIAL_OWNER_1'
export const BENEFICIAL_OWNER_2 = 'BENEFICIAL_OWNER_2'
export const BENEFICIAL_OWNER_3 = 'BENEFICIAL_OWNER_3'

export const PageMutationKey = {
  PAGE_ADMIN: 'admin',
  PAGE_AUTHORIZED_SIGNER: 'authorizedSigner',
  PAGE_BUSINESS_INFO: 'businessInfo',
  PAGE_COMPANY_OWNERS: 'companyOwners',
  PAGE_FINANCE_INFO: 'financeInfo',
  PAGE_REVIEW_AND_SIGN: 'reviewAndSign',
  PAGE_REVIEW_AND_VALIDATE: 'reviewAndValidate',
  PAGE_SIGNUP: 'signup',
  PAGE_SUCCESS: 'success',
}

export const PartnerCodes = {
  GRHPPR: 'grhppr',
  INSBANK: 'insbank',
  KAPITUS: 'kapitus',
  WESTTOWN: 'westtown',
}

export const RightFitSurveyPageContent = [
  {
    checkBoxElements: [
      {
        label: 'less than 2 years',
        name: 'group0_element0',
      },
      {
        label: '2-4 years',
        name: 'group0_element1',
      },
      {
        label: '5-7 years',
        name: 'group0_element2',
      },
      {
        label: '8 or more years',
        name: 'group0_element3',
      },
    ],
    checkBoxSectionTitle: 'How many years has your company been in business?',
  },
  {
    checkBoxElements: [
      {
        label: 'less than $500k',
        name: 'group1_element0',
      },
      {
        label: '$500k-$2.5m',
        name: 'group1_element1',
      },
      {
        label: '$2.5m+',
        name: 'group1_element2',
      },
    ],
    checkBoxSectionTitle: 'What is your company’s annual revenue?',
  },
  {
    checkBoxElements: [
      {
        label: 'less than $25k',
        name: 'group2_element0',
      },
      {
        label: '$25k-$100k',
        name: 'group2_element1',
      },
      {
        label: '$100k-$300k',
        name: 'group2_element2',
      },
      {
        label: '$300k+',
        name: 'group2_element3',
      },
    ],
    checkBoxSectionTitle: 'What is the current cash balance in your company’s bank account?',
  },
  {
    checkBoxElements: [
      {
        label: 'less than 640',
        name: 'group3_element0',
      },
      {
        label: '640-700',
        name: 'group3_element1',
      },
      {
        label: '701-740',
        name: 'group3_element2',
      },
      {
        label: '741+',
        name: 'group3_element3',
      },
      {
        label: 'I don’t know/don’t wish to provide',
        name: 'group3_element4',
      },
    ],
    checkBoxSectionTitle: 'What is the estimated personal credit score of your company’s authorized signer?',
  },
]

export const reviewsAndProTips = (
  pageName,
  shouldShowAuthorizedSignerProTip,
  product = replaceBreakableSpaces('BILL Spend & Expense'),
) => {
  if (pageName?.toLowerCase() === 'resetpassword') {
    return [
      {
        bodyText:
          'We take security very seriously at BILL. Verification only takes a few minutes and helps us protect your privacy.',
        headerText: 'Why do we need to verify your identity?',
      },
      {
        headerText: `“${product} saves my finance team anywhere from 30-50 hours a month. Now, my departments have instant access to the funds they need to get the job done.”`,
        signature: ['Michael Card', 'Crumbl'],
        stars: 5,
      },
    ]
  }
  if (pageName?.toLowerCase() === 'divvyprogramfit') {
    return [
      {
        bodyText: `Gathering a few details up front will help us know if ${product} is a good match for you before you start the application. The more info we have, the better we can help.`,
        headerText: 'Why are we asking for this information?',
      },
    ]
  }
  if (pageName?.toLowerCase() === 'goodfit') {
    return [
      {
        imageName: 'laptops_05',
      },
    ]
  }

  if (pageName === 'businessInfo') {
    return [
      {
        bodyText: `Like any credit application, we need to verify your business and financial information to get started. We know it may feel like a lot, so we provided this help article to explain what we’re asking for and why. Rest assured, your data belongs to you. ${product} doesn’t sell personal or business info and will only use it for verification purposes. View our privacy notice <privacyNoticeLink>here</privacyNoticeLink>.`,
        fmValueIdentifier: 'privacyNoticeLink',
        headerText: `Why does ${product} need this information?`,
        url: URL.BILL_PRIVACY_NOTICE,
      },
      {
        bodyText: 'This is the date your business was created. ',
        headerText: 'What is the Formation date?',
      },
    ]
  }

  if (pageName.toLowerCase() === 'signup') {
    return [
      {
        headerText: '“A free card service that also gives us rewards — you can’t beat that.”',
        signature: ['Steve Plowman', 'Artemis Health'],
        stars: 5,
      },
      {
        bodyText:
          'Make sure you have access to the business email address you provide as you’ll need to access your inbox multiple times throughout this application.',
        headerText: 'Pro tip',
      },
    ]
  }
  if (pageName === 'SignIn') {
    return [
      {
        imageName: 'office_desk_03',
      },
    ]
  }

  if (pageName === 'verify-code') {
    return [
      {
        headerText: `"${product} saves my finance team anywhere from 30-50 hours a month. Now, my departments have instant access to the funds they need to get the job done.”`,
        signature: ['Michael Card', 'Crumbl'],
        stars: 5,
      },
      {
        bodyText: `We take security very seriously at ${product}. Verification only takes a few minutes and helps us protect your privacy.`,
        headerText: 'Why do we need to verify your identity?',
      },
    ]
  }

  if (pageName === 'NotGoodFit') {
    return [
      {
        imageName: 'office_desk_10',
      },
    ]
  }

  if (pageName === 'financeInfo') {
    return [
      {
        bodyText: `Connecting your business bank account allows ${product} to instantly verify your bank and offer the highest line of credit possible based on your financial information. We’ll only use this connection for verification and repayment purposes.`,
        fmValueIdentifier: 'privacyNoticeLink',
        headerText: 'Why do you have to connect your bank account?',
        url: URL.BILL_PRIVACY_NOTICE,
      },
      {
        bodyText:
          'Earn 7x on restaurants, 5x on hotels and 2x on subscriptions for your first $5,000 spent with a weekly billing cycle. Learn more <rewardsLink>https://getdivvy.com/rewards/</rewardsLink>.',
        fmValueIdentifier: 'rewardsLink',
        headerText: 'Accelerate your rewards with a shorter billing cycle.',
        url: URL.BILL_REWARDS_LINK,
      },
    ]
  }

  if (pageName === 'authorizedSigner') {
    const authorizedSignerTextObject = {
      bodyText:
        'An authorized signer is a control person with significant responsibility to control, manage, or direct the company. E.g CEO, CFO, COO, managing member, general partner, president, vice president, treasurer or any other individual who regularly performs similar functions. This individual should have the ability to enter into this relationship and agreement.',
      headerText: 'What is an authorized signer?',
    }
    if (shouldShowAuthorizedSignerProTip) {
      return [
        { ...authorizedSignerTextObject },
        {
          bodyText:
            'Once your application is submitted, Divvy will perform a soft inquiry of the business and the authorized signer’s personal credit to determine the best credit limit for your business. This will not affect your business credit score or the authorized signer’s credit score. Learn more <creditScoreLink>here</creditScoreLink>.',
          fmValueIdentifier: 'creditScoreLink',
          headerText: 'Divvy does not do a hard inquiry or “hard pull” of your business credit.',
          url: URL.CREDIT_IMPACT_LEARN_MORE,
        },
      ]
    }
    return [{ ...authorizedSignerTextObject }]
  }

  if (pageName === 'companyOwners' || pageName === 'beneficialOwners') {
    return [
      {
        bodyText:
          'Also known as a beneficial owner, a company owner is anyone who owns at least 25% of the company. Your company may or may not have a company owner/beneficial owner.',
        headerText: 'Who do I add as a company owner?',
      },
      {
        bodyText:
          'In accordance with the Bank Secrecy Act’s Customer Due Diligence Rule, we require this information to improve financial transparency and prevent criminals and terrorists from misusing companies to disguise illicit activities. The short of it is that it helps us prevent identity theft and application fraud.',
        headerText: 'Why do we need you to list the company owner(s)?',
      },
    ]
  }

  if (pageName === 'reviewAndSign') {
    return [
      {
        imageName: 'people_friends_01',
      },
    ]
  }

  if (pageName === 'application-submitted') {
    return [
      {
        imageName: 'mobile-upload-status-success',
      },
    ]
  }

  return []
}
