import React, { useContext } from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormDropdown, FormContext, FormTextInput } from '@divvy-web/skylab.form'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormElementWrapper } from '../FormInputs'
import { dateStringToEpoch, formatDateString, getFormattedDate } from '../../components/utils'

interface BusinessFormationFieldsProps {
  alwaysShowError?: boolean
  readOnly?: boolean
  shouldDisableField?: (fieldName: string) => boolean
}

const BusinessFormationFields = ({
  alwaysShowError = false,
  readOnly = false,
  shouldDisableField = () => false,
}: BusinessFormationFieldsProps) => {
  const [getClassName, makeTestId] = useNameFormatter('BusinessFormationFields')
  const { setFormValue } = useContext(FormContext) as any

  const onDateInputChange = (event) => {
    const { value } = event.target
    setFormValue('formationDate', formatDateString(value))
  }

  const handleBlur = (event) => {
    const { value } = event.target
    // If the year is entered as two digits, format it correctly
    if (value?.length === 8) {
      const epochDate = dateStringToEpoch(value)
      setFormValue('formationDate', getFormattedDate(epochDate))
    }
  }

  return (
    <>
      <div className='field-container'>
        <FormElementWrapper>
          <FormDropdown
            alwaysShowError={readOnly || alwaysShowError}
            className={getClassName('ownership')}
            control={null}
            dataTestId={makeTestId('ownership')}
            disabled={shouldDisableField && shouldDisableField('ownership')}
            formDataTestId={null}
            isMultiSelect={false}
            isSearchable={false}
            label={
              <FormattedMessage
                defaultMessage='Ownership'
                id='sputnik.BusinessFormationFields__wkn5Ny'
              />
            }
            name='ownership'
            options={[
              { label: 'Public', value: 'PUBLIC' },
              { label: 'Private', value: 'PRIVATE' },
            ]}
            placeholder={
              <FormattedMessage
                defaultMessage='Ownership'
                id='sputnik.BusinessFormationFields__wkn5Ny'
              />
            }
            readOnly={readOnly}
          />
        </FormElementWrapper>
      </div>
      <div className='field-container'>
        <FormElementWrapper isDate>
          <FormTextInput
            // This prop is passed through to TextInput where it does exist
            // @ts-expect-error
            alwaysShowError={alwaysShowError}
            autoComplete='none'
            dataTestId={makeTestId('formation-date')}
            disabled={shouldDisableField && shouldDisableField('formationDate')}
            inputMode='numeric'
            label={
              <FormattedMessage
                defaultMessage='Formation date'
                id='sputnik.BusinessFormationFields__NGjZwx'
              />
            }
            name='formationDate'
            placeholder='Formation date'
            readOnly={readOnly}
            onBlur={(e) => handleBlur(e)}
            onChange={(e) => onDateInputChange(e)}
          />
        </FormElementWrapper>
      </div>
    </>
  )
}

export default BusinessFormationFields
