/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import type * as _Types from '_gql'
const defaultOptions = {} as const
export type AcceptTilaAgreement_acceptTila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta =
  {
    readonly __typename: 'SignableCreditApplicationDocumentMeta'
    readonly signedAt: any | null
    readonly underwritingDecisionUuid: string | null
    readonly underwritingOfferType: _Types.UnderwritingOffer | null
  }

export type AcceptTilaAgreement_acceptTila_SignableCreditApplicationDocument = {
  readonly __typename: 'SignableCreditApplicationDocument'
  readonly content: string
  readonly type: _Types.SignableCreditApplicationDocumentType
  readonly meta: AcceptTilaAgreement_acceptTila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta
}

export type AcceptTilaAgreement_Mutation = {
  readonly __typename: 'Mutation'
  readonly acceptTila: AcceptTilaAgreement_acceptTila_SignableCreditApplicationDocument | null
}

export type AcceptTilaAgreementVariables = _Types.Exact<{
  salesforceCreditId: _Types.Scalars['ID']['input']
  underwritingDecisionUuid: _Types.Scalars['ID']['input']
  underwritingOfferType: _Types.UnderwritingOffer
}>

export type AcceptTilaAgreement = AcceptTilaAgreement_Mutation

export const AcceptTilaAgreementDocument = gql`
  mutation AcceptTilaAgreement(
    $salesforceCreditId: ID!
    $underwritingDecisionUuid: ID!
    $underwritingOfferType: UnderwritingOffer!
  ) {
    acceptTila(
      salesforceCreditId: $salesforceCreditId
      underwritingDecisionUuid: $underwritingDecisionUuid
      underwritingOfferType: $underwritingOfferType
    ) {
      content
      meta {
        signedAt
        underwritingDecisionUuid
        underwritingOfferType
      }
      type
    }
  }
`
export type AcceptTilaAgreementMutationFn = Apollo.MutationFunction<AcceptTilaAgreement, AcceptTilaAgreementVariables>

/**
 * __useAcceptTilaAgreement__
 *
 * To run a mutation, you first call `useAcceptTilaAgreement` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTilaAgreement` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTilaAgreement, { data, loading, error }] = useAcceptTilaAgreement({
 *   variables: {
 *      salesforceCreditId: // value for 'salesforceCreditId'
 *      underwritingDecisionUuid: // value for 'underwritingDecisionUuid'
 *      underwritingOfferType: // value for 'underwritingOfferType'
 *   },
 * });
 */
export function useAcceptTilaAgreement(
  baseOptions?: Apollo.MutationHookOptions<AcceptTilaAgreement, AcceptTilaAgreementVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptTilaAgreement, AcceptTilaAgreementVariables>(AcceptTilaAgreementDocument, options)
}
export type AcceptTilaAgreementHookResult = ReturnType<typeof useAcceptTilaAgreement>
export type AcceptTilaAgreementMutationResult = Apollo.MutationResult<AcceptTilaAgreement>
export type AcceptTilaAgreementMutationOptions = Apollo.BaseMutationOptions<
  AcceptTilaAgreement,
  AcceptTilaAgreementVariables
>
