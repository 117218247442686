import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Icon from '@divvy-web/skylab.icon'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import GetDecisions from '../../pages/gql/GetDecisions.gql'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useDivvyUUID from '../../hooks/useDivvyUUID'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import CustomerAssistanceButton from '../../components/CustomerAssistanceButton/CustomerAssistanceButton'
import {
  PATHNAME_CONSTS,
  STANDARD_OFFER_PATH,
  VARIABLE_OFFER_PATH,
  SECURED_OFFER_PATH,
} from '../../resources/constants'
import {
  getOfferType,
  STANDARD_LINE_OFFER,
  VARIABLE_LINE_OFFER,
  SECURED_LINE_OFFER,
} from '../../utils/underwritingUtils'
import OfferTile from './OfferTile'
import OfferInfoMobilePopSheet from './OfferInfoMobilePopSheet'
import { mobileSuccessPreApprovedOffersCss, webSuccessPreApprovedOffersCss } from './successPreApprovedOffersStyles'

const SuccessPreApprovedOffers = () => {
  const navigate = useNavigate()
  const [companyDivvyUuid] = useDivvyUUID()
  const { isMobile } = useDeviceDetect()
  const { sideBarIcon } = useGetBillProduct()
  const [isShowingStandardOfferPopSheet, setIsShowingStandardOfferPopSheet] = useState(false)
  const [isShowingVariableOfferPopSheet, setIsShowingVariableOfferPopSheet] = useState(false)
  const [isShowingSecuredOfferPopSheet, setIsShowingSecuredOfferPopSheet] = useState(false)
  const [getClassName, makeTestId] = useNameFormatter('SuccessPreApprovedOffers')

  const { data: decisionsData } = useQuery(GetDecisions, {
    fetchPolicy: 'network-only',
    skip: !companyDivvyUuid,
    variables: { companyId: companyDivvyUuid },
  })
  const decisions = decisionsData?.decisions || []

  const standardLineOffer = getOfferType(decisions, STANDARD_LINE_OFFER)
  const variableLineOffer = getOfferType(decisions, VARIABLE_LINE_OFFER)
  const securedLineOffer = getOfferType(decisions, SECURED_LINE_OFFER)

  const hasStandardOffer = standardLineOffer != null
  const hasVariableOffer = variableLineOffer != null
  const hasSecuredOffer = securedLineOffer != null

  const hasThreeOffers = hasStandardOffer && hasVariableOffer && hasSecuredOffer
  const offerCount = hasThreeOffers ? 3 : 2

  const standardApprovedAmount = standardLineOffer?.approvedAmountInCents
  const variableApprovedAmount = variableLineOffer?.approvedAmountInCents

  const toggleStandardOfferPopSheet = () => {
    setIsShowingStandardOfferPopSheet(!isShowingStandardOfferPopSheet)
  }

  const toggleVariableOfferPopSheet = () => {
    setIsShowingVariableOfferPopSheet(!isShowingVariableOfferPopSheet)
  }

  const toggleSecuredOfferPopSheet = () => {
    setIsShowingSecuredOfferPopSheet(!isShowingSecuredOfferPopSheet)
  }

  const navigateToStandardCreditLineOffer = () => {
    navigate(PATHNAME_CONSTS.STANDARD_OFFER_PATH)
  }

  const navigateToVariableCreditLineOffer = () => {
    navigate(PATHNAME_CONSTS.VARIABLE_OFFER_PATH)
  }

  const navigateToSecuredCreditLineOffer = () => {
    navigate(PATHNAME_CONSTS.SECURED_OFFER_PATH)
  }

  const handleBackToDashboardClick = () => {
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH)
  }

  const standardOfferTopCaption = (
    <FormattedMessage
      defaultMessage='Your credit limit is fixed and typically won’t change.'
      id='sputnik.StandardCreditLineOfferPopSheet__kT0CVZ'
    />
  )

  const standardOfferBottomCaption = (
    <FormattedMessage
      defaultMessage='Great for companies that require a steady credit limit from month to month.'
      id='sputnik.SuccessPreApprovedOffers__YFvyE/'
    />
  )

  const variableOfferTopCaption = (
    <FormattedMessage
      defaultMessage='Your credit limit may adjust based on your bank account balance.'
      id='sputnik.SuccessPreApprovedOffers__1hE+fo'
    />
  )

  const variableOfferBottomCaption = (
    <FormattedMessage
      defaultMessage='Ideal for companies that need a flexible credit limit that grows with their business.'
      id='sputnik.SuccessPreApprovedOffers__+j1YHY'
    />
  )

  const securedOfferTopCaption = (
    <FormattedMessage
      defaultMessage='Your credit limit is funded by your own cash deposit, which you can adjust at any time.'
      id='sputnik.SuccessPreApprovedOffers__SPez2F'
    />
  )

  const securedOfferBottomCaption = (
    <FormattedMessage
      defaultMessage='Perfect for companies looking to maximize spending. Earn 1.5% cash back rewards on all eligible purchases.'
      id='sputnik.SuccessPreApprovedOffers__IvzXNC'
    />
  )

  return (
    <div
      css={isMobile ? mobileSuccessPreApprovedOffersCss : webSuccessPreApprovedOffersCss}
      data-testid={makeTestId('')}
    >
      <CustomerAssistanceButton shouldUseSpecialMobileStyles />
      <div className='main-container fs-unmask'>
        <header className={getClassName('header-content')}>
          <section className={getClassName('title-and-subtitle')}>
            <p className={getClassName('title')}>
              <FormattedMessage
                defaultMessage='Congrats! You qualify for {offerCount} offers.'
                id='sputnik.SuccessPreApprovedOffers__8YMraL'
                values={{ offerCount }}
              />
            </p>
            <p className={getClassName('subtitle')}>
              <FormattedMessage
                defaultMessage='Based on the information from your credit application, you’re eligible for the following credit offers:'
                id='sputnik.SuccessPreApprovedOffers__et15an'
              />
            </p>
          </section>
          <section>
            <BasicButton
              className={getClassName('return-to-dashboard')}
              dataTestId={makeTestId('return-to-dashboard')}
              type={BASIC_BUTTON_TYPE_FLAT}
              onClick={handleBackToDashboardClick}
            >
              <FormattedMessage
                defaultMessage='Return to dashboard'
                id='sputnik.SuccessPreApprovedOffers__Awhp8T'
              />
            </BasicButton>
          </section>
          {!isMobile && <div className={getClassName('bill-logo')}>{sideBarIcon}</div>}
        </header>
        <main className={getClassName('offer-info-and-tiles')}>
          <div className={getClassName('offer-info')}>
            <p className={getClassName('offer-info-list-title')}>
              <FormattedMessage
                defaultMessage='Included with all offers:'
                id='sputnik.SuccessPreApprovedOffers__MJHQ84'
              />
            </p>
            <ul
              className={getClassName('offer-info-list')}
              data-testid={makeTestId('offer-info-list')}
            >
              <li className={getClassName('offer-info-list-item')}>
                <FormattedMessage
                  defaultMessage='Earn rewards as you spend'
                  id='sputnik.SuccessPreApprovedOffers__g+2llw'
                />
              </li>
              <li className={getClassName('offer-info-list-item')}>
                <FormattedMessage
                  defaultMessage='Access to BILL Spend & Expense platform'
                  id='sputnik.SuccessPreApprovedOffers__g4bZtX'
                />
              </li>
              <li className={getClassName('offer-info-list-item')}>
                <FormattedMessage
                  defaultMessage='Free to use'
                  id='sputnik.SuccessPreApprovedOffers__dz5v8V'
                />
              </li>
              <li className={getClassName('offer-info-list-item')}>
                <FormattedMessage
                  defaultMessage='Pay zero interest fees'
                  id='sputnik.SuccessPreApprovedOffers__S4fbfk'
                />
              </li>
            </ul>
          </div>

          {/* Standard offer tiles */}
          {hasStandardOffer && (
            <OfferTile
              approvedAmount={standardApprovedAmount}
              bottomCaption={isMobile ? null : standardOfferBottomCaption}
              image='standard-credit-line-tile'
              title={
                <FormattedMessage
                  defaultMessage='Standard credit line'
                  id='sputnik.SuccessPreApprovedOffers__WQCeHH'
                />
              }
              topCaption={standardOfferTopCaption}
              onTileClick={isMobile ? toggleStandardOfferPopSheet : navigateToStandardCreditLineOffer}
            />
          )}

          {/* Variable offer tiles */}
          {hasVariableOffer && (
            <OfferTile
              approvedAmount={variableApprovedAmount}
              bottomCaption={isMobile ? null : variableOfferBottomCaption}
              image='variable-credit-line-tile'
              title={
                <FormattedMessage
                  defaultMessage='Variable credit line'
                  id='sputnik.SuccessPreApprovedOffers__8ugHZu'
                />
              }
              topCaption={variableOfferTopCaption}
              onTileClick={isMobile ? toggleVariableOfferPopSheet : navigateToVariableCreditLineOffer}
            />
          )}

          {/* Secured offer tiles */}
          {hasSecuredOffer && (
            <OfferTile
              bottomCaption={isMobile ? null : securedOfferBottomCaption}
              image='secured-credit-line-tile'
              title={
                <p className={getClassName('secured-line-title')}>
                  <FormattedMessage
                    defaultMessage='Secured credit line'
                    id='sputnik.SuccessPreApprovedOffers__Zm8wJX'
                  />
                </p>
              }
              topCaption={securedOfferTopCaption}
              onTileClick={isMobile ? toggleSecuredOfferPopSheet : navigateToSecuredCreditLineOffer}
            />
          )}
        </main>
      </div>
      {/* Standard offer mobile popsheet */}
      {isMobile && (
        <>
          <OfferInfoMobilePopSheet
            bottomCaption={standardOfferBottomCaption}
            image='standard-credit-line-tile'
            isShowing={isShowingStandardOfferPopSheet}
            navigateToSelectedOffer={navigateToStandardCreditLineOffer}
            subtitle={
              <FormattedMessage
                defaultMessage="What is a 'standard credit line'?"
                id='sputnik.SuccessPreApprovedOffers__9RNpI8'
              />
            }
            togglePopSheet={toggleStandardOfferPopSheet}
            topCaption={standardOfferTopCaption}
          />
          {/* Variable offer mobile popsheet */}
          <OfferInfoMobilePopSheet
            bottomCaption={variableOfferBottomCaption}
            image='variable-credit-line-tile'
            isShowing={isShowingVariableOfferPopSheet}
            navigateToSelectedOffer={navigateToVariableCreditLineOffer}
            subtitle={
              <FormattedMessage
                defaultMessage="What is a 'variable credit line'?"
                id='sputnik.SuccessPreApprovedOffers__x83Whl'
              />
            }
            togglePopSheet={toggleVariableOfferPopSheet}
            topCaption={variableOfferTopCaption}
          />
          {/* Secured offer mobile popsheet */}
          <OfferInfoMobilePopSheet
            bottomCaption={securedOfferBottomCaption}
            image='secured-credit-line-tile'
            isShowing={isShowingSecuredOfferPopSheet}
            navigateToSelectedOffer={navigateToSecuredCreditLineOffer}
            subtitle={
              <FormattedMessage
                defaultMessage="What is a 'secured credit line'?"
                id='sputnik.SuccessPreApprovedOffers__N1HW4b'
              />
            }
            togglePopSheet={toggleSecuredOfferPopSheet}
            topCaption={securedOfferTopCaption}
          />
        </>
      )}
    </div>
  )
}

export default SuccessPreApprovedOffers
