import React from 'react'
import { useQuery } from '@apollo/client'
import { Routes, Route, useParams } from 'react-router-dom'
import { useCanary } from '@divvy-web/canary'
import VariableCreditLineOffer from '../VariableCreditLineOffer'
import StandardCreditLineOffer from '../StandardCreditLineOffer'
import SuccessPreApprovedDualOffer from '../SuccessPreApprovedDualOffer'
import SuccessFrozenCredit from '../SuccessFrozenCredit'
import SuccessWorkingOutDetails from '../SuccessWorkingOutDetails'
import useDivvyUUID from '../../hooks/useDivvyUUID'
import GetDecisions from '../../pages/gql/GetDecisions.gql'
import GetApplicationStatuses from '../Dashboard/gql/GetApplicationStatuses.gql'
import GetApplicationProcessedCreditData from '../../pages/gql/GetApplicationProcessedCreditData.gql'
import RequireAuth from '../../components/Routes/RequireAuth'
import ApplicationDeclined from '../ApplicationDeclined/'
import AcceptOffer from '../AcceptOffer/AcceptOffer'
import SuccessPreApprovedOffers from '../SuccessPreApprovedOffers/SuccessPreApprovedOffers'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { matchEmail } from '../../utils/dataUtils'
import { useAuth } from '../../auth'
import StandardCreditLineOfferAcceptance from './StandardCreditLineOfferAcceptance'
import VariableCreditLineOfferAcceptance from './VariableCreditLineOfferAcceptance'
import SecuredCreditLineOfferAcceptance from './SecuredCreditLineOfferAcceptance'
import { DecisionContext } from './DecisionsContext'
import { FIXED_LINE, SECURED_LINE, VARIABLE_LINE } from './applicationProcessedUtils'

const ApplicationProcessed = () => {
  const { appId } = useParams()
  const { email } = useAuth()
  const [companyId] = useDivvyUUID()
  const showSecuredLineRoutes = useCanary('show-secured-line')
  const { data: decisionsData } = useQuery(GetDecisions, {
    fetchPolicy: 'network-only',
    skip: !companyId,
    variables: { companyId },
  })
  const { data: applicationData } = useQuery(GetApplicationStatuses)

  const { data: applicationProcessedCreditData } = useQuery(GetApplicationProcessedCreditData, {
    skip: !appId,
    variables: { creditApplicationId: appId },
  })

  const desiredCredit = applicationProcessedCreditData?.creditApplication?.financeInfo?.desiredCredit
  const authSignerEmail = applicationProcessedCreditData?.creditApplication?.authorizedSigner?.email
  const isLoggedInUserAuthorizedSigner = matchEmail(authSignerEmail, email)

  const decisions = decisionsData?.decisions || []
  const decisionId = decisions?.[0]?.id

  const standardLineOffer = decisions?.find((decision) => decision?.offerType === FIXED_LINE)
  const variableLineOffer = decisions?.find((decision) => decision?.offerType === VARIABLE_LINE)
  const securedLineOffer = decisions?.find((decision) => decision?.offerType === SECURED_LINE)

  const hasOnlyOneDecision = decisions?.length === 1
  const isStandardOfferOnly = !!(hasOnlyOneDecision && standardLineOffer)
  const isVariableOfferOnly = !!(hasOnlyOneDecision && variableLineOffer)
  const isSecuredOfferOnly = !!(hasOnlyOneDecision && securedLineOffer)

  // These variables below will either be updated or removed in following MR's for the new secured line flow
  const variableOfferOnly = decisions?.length === 1 && decisions?.[0]?.underwritingMethod === 'auto_cash_underwriting'
  const filteredStandardOffer = decisions.filter((decision) => decision.underwritingMethod !== 'auto_cash_underwriting')
  const filteredVariableOffer = decisions.filter((decision) => decision.underwritingMethod === 'auto_cash_underwriting')
  const applicationCount = applicationData?.creditApplications?.length
  const hasApplications = applicationCount > 0

  const standardOfferApprovedAmount = filteredStandardOffer?.[0]?.approvedAmountInCents
  const variableOfferApprovedAmount = filteredVariableOffer?.[0]?.approvedAmountInCents
  const approvedAmount = decisions?.[0]?.approvedAmountInCents

  return (
    <>
      <DecisionContext.Provider
        value={{
          decisionId,
          decisions,
          desiredCredit,
          securedLineOffer,
          variableLineOffer,
          standardLineOffer,
          isLoggedInUserAuthorizedSigner,
          authSignerEmail,
        }}
      >
        <Routes>
          {showSecuredLineRoutes && (
            <>
              <Route
                element={
                  <RequireAuth>
                    <SuccessPreApprovedOffers />
                  </RequireAuth>
                }
                path={PATHNAME_CONSTS.MULTI_CREDIT_LINE_OFFER_PATH}
              />
              <Route
                element={
                  <RequireAuth>
                    <StandardCreditLineOfferAcceptance />
                  </RequireAuth>
                }
                path={PATHNAME_CONSTS.MULTI_OFFER_TO_STANDARD_OFFER_PATH}
              />
              <Route
                element={
                  <RequireAuth>
                    <VariableCreditLineOfferAcceptance />
                  </RequireAuth>
                }
                path={PATHNAME_CONSTS.MULTI_OFFER_TO_VARIABLE_OFFER_PATH}
              />
              <Route
                element={
                  <RequireAuth>
                    <SecuredCreditLineOfferAcceptance />
                  </RequireAuth>
                }
                path={PATHNAME_CONSTS.MULTI_OFFER_TO_SECURED_OFFER_PATH}
              />
              <Route
                element={
                  <RequireAuth>
                    {isStandardOfferOnly && <StandardCreditLineOfferAcceptance />}
                    {isVariableOfferOnly && <VariableCreditLineOfferAcceptance />}
                    {isSecuredOfferOnly && <SecuredCreditLineOfferAcceptance />}
                  </RequireAuth>
                }
                path={PATHNAME_CONSTS.SINGLE_CREDIT_LINE_OFFER_PATH}
              />
            </>
          )}

          {/* Any 'dual-offer' or 'pre-approved' route will be removed once secured line is launched */}
          <Route
            element={
              <RequireAuth>
                <SuccessPreApprovedDualOffer
                  desiredCredit={desiredCredit}
                  filteredStandardOffer={filteredStandardOffer}
                  filteredVariableOffer={filteredVariableOffer}
                />
              </RequireAuth>
            }
            path='/dual-offer'
          />
          <Route
            element={
              <RequireAuth>
                <StandardCreditLineOffer desiredCredit={desiredCredit} />
              </RequireAuth>
            }
            path='/dual-offer/standard-pre-approved'
          />
          <Route
            element={
              <RequireAuth>
                <VariableCreditLineOffer desiredCredit={desiredCredit} />
              </RequireAuth>
            }
            path='/dual-offer/variable-pre-approved'
          />
          <Route
            element={
              <RequireAuth>
                {!variableOfferOnly && <StandardCreditLineOffer desiredCredit={desiredCredit} />}
                {variableOfferOnly && <VariableCreditLineOffer desiredCredit={desiredCredit} />}
              </RequireAuth>
            }
            path='/pre-approved'
          />

          <Route
            exact
            element={<AcceptOffer approvedAmount={approvedAmount} />}
            path='/pre-approved/next-steps'
          />
          <Route
            exact
            element={<AcceptOffer approvedAmount={standardOfferApprovedAmount} />}
            path='dual-offer/standard-pre-approved/next-steps'
          />
          <Route
            exact
            element={<AcceptOffer approvedAmount={variableOfferApprovedAmount} />}
            path='dual-offer/variable-pre-approved/next-steps'
          />
          <Route
            element={<RequireAuth component={SuccessFrozenCredit} />}
            path='/frozen-credit'
          />
          <Route
            element={<RequireAuth component={SuccessWorkingOutDetails} />}
            path='/working-out-details'
          />
          <Route
            element={
              <RequireAuth>
                <ApplicationDeclined hasApplications={hasApplications} />
              </RequireAuth>
            }
            path='/declined'
          />
        </Routes>
      </DecisionContext.Provider>
    </>
  )
}

export default ApplicationProcessed
