const titleOptions = [
  'Chief Executive Officer',
  'Chief Financial Officer',
  'Chief Operating Officer',
  'Managing Member',
  'General Partner',
  'President',
  'Vice President',
  'Treasurer',
  'Owner',
  'Executive Director',
  'Director',
  'Founder',
  'Controller',
  'Principal',
]

export const authorizedSignerTitleOptions = titleOptions.map((title) => ({
  value: title,
  label: title,
}))
