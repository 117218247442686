/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import type * as _Types from '_gql'
const defaultOptions = {} as const
export type GetCreditApplicationTilaInfo_creditApplication_CreditApplication_authorizedSigner_CreditApplicationPerson =
  {
    readonly __typename: 'CreditApplicationPerson'
    readonly id: string | null
    readonly email: string | null
    readonly cacheRole: string | null
  }

export type GetCreditApplicationTilaInfo_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo_bankInfo_BankInfo =
  { readonly __typename: 'BankInfo'; readonly accountNumberLastFour: string | null }

export type GetCreditApplicationTilaInfo_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo =
  {
    readonly __typename: 'CreditApplicationFinanceInfo'
    readonly bankInfo: GetCreditApplicationTilaInfo_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo_bankInfo_BankInfo | null
  }

export type GetCreditApplicationTilaInfo_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta =
  {
    readonly __typename: 'SignableCreditApplicationDocumentMeta'
    readonly signedAt: any | null
    readonly underwritingDecisionUuid: string | null
    readonly underwritingOfferType: _Types.UnderwritingOffer | null
  }

export type GetCreditApplicationTilaInfo_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument = {
  readonly __typename: 'SignableCreditApplicationDocument'
  readonly type: _Types.SignableCreditApplicationDocumentType
  readonly content: string
  readonly meta: GetCreditApplicationTilaInfo_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument_meta_SignableCreditApplicationDocumentMeta
}

export type GetCreditApplicationTilaInfo_creditApplication_CreditApplication = {
  readonly __typename: 'CreditApplication'
  readonly salesforceCreditId: string
  readonly dsaVersion: string | null
  readonly recordType: string | null
  readonly appVersion: number | null
  readonly authorizedSigner: GetCreditApplicationTilaInfo_creditApplication_CreditApplication_authorizedSigner_CreditApplicationPerson | null
  readonly financeInfo: GetCreditApplicationTilaInfo_creditApplication_CreditApplication_financeInfo_CreditApplicationFinanceInfo | null
  readonly tila?: GetCreditApplicationTilaInfo_creditApplication_CreditApplication_tila_SignableCreditApplicationDocument | null
}

export type GetCreditApplicationTilaInfo_Query = {
  readonly __typename: 'Query'
  readonly creditApplication: GetCreditApplicationTilaInfo_creditApplication_CreditApplication | null
}

export type GetCreditApplicationTilaInfoVariables = _Types.Exact<{
  creditApplicationId: _Types.Scalars['ID']['input']
  underwritingOfferType: _Types.UnderwritingOffer
  underwritingDecisionUuid: _Types.Scalars['ID']['input']
  isAuthSigner: _Types.Scalars['Boolean']['input']
}>

export type GetCreditApplicationTilaInfo = GetCreditApplicationTilaInfo_Query

export const GetCreditApplicationTilaInfoDocument = gql`
  query GetCreditApplicationTilaInfo(
    $creditApplicationId: ID!
    $underwritingOfferType: UnderwritingOffer!
    $underwritingDecisionUuid: ID!
    $isAuthSigner: Boolean!
  ) {
    creditApplication(creditApplicationId: $creditApplicationId) {
      salesforceCreditId
      authorizedSigner {
        id
        email
        cacheRole
      }
      dsaVersion
      recordType
      appVersion
      financeInfo {
        bankInfo {
          accountNumberLastFour
        }
      }
      tila(underwritingOfferType: $underwritingOfferType, underwritingDecisionUuid: $underwritingDecisionUuid)
        @include(if: $isAuthSigner) {
        type
        content
        meta {
          signedAt
          underwritingDecisionUuid
          underwritingOfferType
        }
      }
    }
  }
`

/**
 * __useGetCreditApplicationTilaInfo__
 *
 * To run a query within a React component, call `useGetCreditApplicationTilaInfo` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditApplicationTilaInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditApplicationTilaInfo({
 *   variables: {
 *      creditApplicationId: // value for 'creditApplicationId'
 *      underwritingOfferType: // value for 'underwritingOfferType'
 *      underwritingDecisionUuid: // value for 'underwritingDecisionUuid'
 *      isAuthSigner: // value for 'isAuthSigner'
 *   },
 * });
 */
export function useGetCreditApplicationTilaInfo(
  baseOptions: Apollo.QueryHookOptions<GetCreditApplicationTilaInfo, GetCreditApplicationTilaInfoVariables> &
    ({ variables: GetCreditApplicationTilaInfoVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCreditApplicationTilaInfo, GetCreditApplicationTilaInfoVariables>(
    GetCreditApplicationTilaInfoDocument,
    options,
  )
}
export function useGetCreditApplicationTilaInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCreditApplicationTilaInfo, GetCreditApplicationTilaInfoVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCreditApplicationTilaInfo, GetCreditApplicationTilaInfoVariables>(
    GetCreditApplicationTilaInfoDocument,
    options,
  )
}
export function useGetCreditApplicationTilaInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCreditApplicationTilaInfo, GetCreditApplicationTilaInfoVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCreditApplicationTilaInfo, GetCreditApplicationTilaInfoVariables>(
    GetCreditApplicationTilaInfoDocument,
    options,
  )
}
export type GetCreditApplicationTilaInfoHookResult = ReturnType<typeof useGetCreditApplicationTilaInfo>
export type GetCreditApplicationTilaInfoLazyQueryHookResult = ReturnType<
  typeof useGetCreditApplicationTilaInfoLazyQuery
>
export type GetCreditApplicationTilaInfoSuspenseQueryHookResult = ReturnType<
  typeof useGetCreditApplicationTilaInfoSuspenseQuery
>
export type GetCreditApplicationTilaInfoQueryResult = Apollo.QueryResult<
  GetCreditApplicationTilaInfo,
  GetCreditApplicationTilaInfoVariables
>
