import React from 'react'
import { func, number, object, string } from 'prop-types'
import { FormattedMessage, FormattedCurrency } from '@divvy-web/i18n'
import BasicButton from '@divvy-web/skylab.basicbutton'
import Icon from '@divvy-web/skylab.icon'
import Tile from '@divvy-web/skylab.tile'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import ImageWrapper from '../../components/ImageWrapper'
import { offerTileCss } from './offerTileStyles'

const OfferTile = ({ onTileClick, approvedAmount, bottomCaption, image, topCaption, title }) => {
  const { isMobile } = useDeviceDetect()
  const [getClassName, makeTestId] = useNameFormatter('OfferTile')

  return (
    <Tile
      className={getClassName('')}
      css={offerTileCss}
      dataTestId={makeTestId('')}
      onClick={onTileClick}
    >
      <div className={getClassName('image-and-text-wrapper')}>
        <div>
          <ImageWrapper
            alt='security-lock'
            className={'image'}
            imageName={image}
          />
        </div>
        <div className={getClassName('text-wrapper')}>
          {!isMobile && <div className={getClassName('desktop-title')}>{title}</div>}
          {approvedAmount && (
            <p
              className={getClassName('offer-tile-approved-amount')}
              data-testid={makeTestId('offer-tile-approved-amount')}
            >
              <FormattedCurrency
                maximumFractionDigits={0}
                minimumFractionDigits={2}
                value={approvedAmount}
              />
            </p>
          )}
          {isMobile && <div className={getClassName('mobile-title')}>{title}</div>}
          <p className={getClassName('top-caption')}>{topCaption}</p>
          {!isMobile && <p className={getClassName('bottom-caption')}>{bottomCaption}</p>}
        </div>
      </div>
      <div className={getClassName('footer')}>
        {isMobile && (
          <Icon
            name='chevronRight'
            size='large'
          />
        )}
        {!isMobile && (
          <BasicButton className={getClassName('review-offer')}>
            <FormattedMessage
              defaultMessage='View offer details'
              id='sputnik.SuccessPreApprovedOffers__nvR9jj'
            />
          </BasicButton>
        )}
      </div>
    </Tile>
  )
}

OfferTile.propTypes = {
  onTileClick: func,
  image: string,
  approvedAmount: number,
  title: object,
  topCaption: object,
  bottomCaption: object,
}

export default OfferTile
