import { FormattedMessage } from '@divvy-web/i18n'
import { BASIC_BUTTON_COLOR_ACTION, BASIC_BUTTON_COLOR_NEUTRAL } from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { css } from '@emotion/core'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import CancelConfirm from '@divvy-web/skylab.cancelconfirm'
import { useIntersectionObserver } from 'usehooks-ts'
import ImageWrapper from '../../../components/ImageWrapper'

interface AgreementModalProps {
  isAcceptingTilaAgreement: boolean
  isShowing: boolean
  hasAgreedToOfferSummary: boolean
  tilaContent: string
  onCancel: () => void
  onAgreeClick: () => void
}

const AgreementModal = ({
  isAcceptingTilaAgreement,
  isShowing,
  hasAgreedToOfferSummary,
  tilaContent,
  onCancel,
  onAgreeClick,
}: AgreementModalProps) => {
  const [makeTestId] = useNameFormatter('AgreementModal')
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 1,
  })

  return (
    <Modal
      hasScrollableContent
      actions={
        !hasAgreedToOfferSummary && (
          <CancelConfirm
            cancelColor={BASIC_BUTTON_COLOR_NEUTRAL}
            cancelText={
              <FormattedMessage
                defaultMessage='Cancel'
                id='sputnik.AgreementModal__47FYwb'
              />
            }
            confirmText={
              <FormattedMessage
                defaultMessage='Agree'
                id='sputnik.AgreementModal__ISly67'
              />
            }
            dataTestId={makeTestId('')}
            inactive={isAcceptingTilaAgreement || !isIntersecting}
            submitColor={BASIC_BUTTON_COLOR_ACTION}
            onCancel={onCancel}
            onConfirm={onAgreeClick}
          />
        )
      }
      className='fs-unmask'
      css={modalCss}
      dataTestId={makeTestId('')}
      headerColor='accent'
      headerImage={
        <ImageWrapper
          alt='hand holding paper'
          imageName='dashboard-application-persona-3'
        />
      }
      isShowing={isShowing}
      title={
        <FormattedMessage
          defaultMessage='Review your offer summary'
          id='sputnik.AgreementDisplayModal__962yi/'
        />
      }
      onCloseClick={onCancel}
    >
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{tilaContent}</ReactMarkdown>
      <span
        ref={ref}
        data-testId='ref-container'
      />
    </Modal>
  )
}

const modalCss = css`
  .Modal-main {
    max-width: 1167px;
    max-height: 818px;

    .Modal-content {
      padding: var(--tri-space-500) var(--tri-space-1500);
    }

    .AgreementModal-react-markdown-container {
      .agreement-header {
        margin-bottom: var(--tri-space-200);
      }

      .agreement-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .agreement-table,
      p {
        border-collapse: collapse;
        max-width: 800px;
      }

      .agreement-table td {
        padding: var(--tri-space-100);
      }

      .agreement-table th,
      .agreement-table td {
        border: 2px solid var(--tri-color-text-primary);
      }

      .agreement-signature-table {
        width: 100%;
      }

      .agreement-signature-table-gap {
        margin: 0;
        width: 100%;
      }

      .agreement-signature-value {
        margin: 0;
        min-width: 200px;
        text-align: left !important;

        pre {
          margin: 0;
          padding: 0;
        }
      }

      .agreement-signature-label {
        border-top: 2px solid var(--tri-color-text-primary);
        margin: 0;
        text-align: left !important;
        min-width: 200px;
      }
    }
  }
`

export default AgreementModal
