import { FormattedMessage } from '@divvy-web/i18n'
import { FormContext, FormTextInput } from '@divvy-web/skylab.form'
import Spinner from '@divvy-web/skylab.spinner'
import TextLink from '@divvy-web/skylab.textlink'
import { bool, func } from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from '../../auth'
import { FormButtons, TimeoutWarningModal } from '../../components'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import FormEmailInput from '../../components/FormInputs/FormEmailInput'
import RecaptchaDisclaimer from '../../components/RecaptchaDisclaimer'
import {
  getFormattedNumOfEmployees,
  getFormattedUSPhoneNumber,
  normalizeSmartQuotesAndEmdashes,
} from '../../components/utils'
import useGetBillProduct from '../../hooks/useGetBillProduct'
import { URL } from '../../resources/constants'
import SignUpBusinessFields from './SignUpBusinessFields'
import { signupFormStyles, signupWrapperStyles, usCountryCodeStyles } from './SignUpFormStyles'
import { NATURAL_PERSON } from './signUpConstants'

const SignUpForm = ({ onNextClick = (values) => {}, submitting = false, verifyReCaptcha }) => {
  const { email: loggedInEmail, isAuthenticated } = useAuth()
  const { formIsValid, formValues, getFormValue, validationErrors } = useContext(FormContext)
  const [isPersonal, setIsPersonal] = useState(false)
  const { email: submittedEmail } = formValues
  const { BILL_PRIVACY_NOTICE, BILL_TERMS_AND_CONDITIONS } = URL
  const { company, product } = useGetBillProduct()

  useEffect(() => {
    setIsPersonal(getFormValue('entityType') === NATURAL_PERSON)
  }, [formValues, getFormValue])

  const numOfEmployeesError = validationErrors?.numOfEmployees
  const getNumOfEmployeesErrorCaption = () => {
    if (typeof numOfEmployeesError === 'string') return numOfEmployeesError
    if (typeof numOfEmployeesError === 'object') return numOfEmployeesError?.[0]
    return null
  }

  const usCountryCode = (
    <div css={usCountryCodeStyles}>
      <FormattedMessage
        defaultMessage='🇺🇸 +1'
        id='sputnik.SignUpForm__sShckg'
      />
    </div>
  )

  const getFullForm = () => {
    if (submitting) {
      return (
        <div
          className='spinner-wrapper-form'
          data-testid='new-signup-spinner'
        >
          <Spinner centered />
        </div>
      )
    }

    return (
      <>
        <section
          css={signupFormStyles({ isPersonal })}
          id='new-signup-form'
        >
          <div className='field-row'>
            <div className='field-container fs-unmask'>
              <FormElementWrapper>
                <FormTextInput
                  autoComplete='none'
                  className='fs-mask'
                  label='First name'
                  maxLength={30}
                  name='firstName'
                  normalize={normalizeSmartQuotesAndEmdashes}
                  placeholder='First name'
                />
              </FormElementWrapper>
            </div>
            <div className='field-container fs-unmask'>
              <FormElementWrapper>
                <FormTextInput
                  autoComplete='none'
                  className='fs-mask'
                  label='Last name'
                  maxLength={30}
                  name='lastName'
                  normalize={normalizeSmartQuotesAndEmdashes}
                  placeholder='Last name'
                />
              </FormElementWrapper>
            </div>
          </div>
          <div className='field-row'>
            <div className='field-container fs-unmasked phone-number'>
              <FormElementWrapper>
                <FormTextInput
                  className='fs-mask'
                  inputMode='numeric'
                  label='Mobile phone number'
                  leftIcon={usCountryCode}
                  maxLength={14}
                  name='phoneNumber'
                  normalize={getFormattedUSPhoneNumber}
                  placeholder={
                    <FormattedMessage
                      defaultMessage='Phone number'
                      id='sputnik.SignUpForm__jdJhOL'
                    />
                  }
                />
              </FormElementWrapper>
            </div>
            <div className='field-container fs-unmask'>
              <FormEmailInput
                readOnly
                autoComplete='on'
                className='signup-email-field fs-mask'
                label='Your business email'
                name='email'
                placeholder='Your business email'
                type='email'
              />
            </div>
          </div>
          {/* Business name > 52 characters causes issues with downstream processes */}
          <div className='field-row fs-unmask'>
            <div className='field-container'>
              <FormElementWrapper>
                <FormTextInput
                  autoComplete='none'
                  label='Legal business name'
                  maxLength={52}
                  name='legalBusinessName'
                  normalize={normalizeSmartQuotesAndEmdashes}
                  placeholder='Legal business name'
                />
              </FormElementWrapper>
            </div>
            <div className='field-container'>
              <FormElementWrapper>
                <FormTextInput
                  autoComplete='none'
                  className='numOfEmployees'
                  errorCaption={getNumOfEmployeesErrorCaption()}
                  hasError={!!numOfEmployeesError}
                  inputMode='numeric'
                  label='Number of full time employees'
                  maxLength={6}
                  name='numOfEmployees'
                  normalize={getFormattedNumOfEmployees}
                  placeholder='Number of full time employees'
                />
              </FormElementWrapper>
            </div>
          </div>
          <SignUpBusinessFields isPersonal={isPersonal} />
          <div
            className='acknowledgement fs-unmask'
            data-testid='acknowledgement'
          >
            <FormattedMessage
              defaultMessage="By clicking next, you acknowledge that you’re authorized to provide contact information about your company and any other authorized signer or beneficial owner. You also acknowledge that any contact information provided may be used by {product} and/or its affiliates to contact you. By continuing, you agree to {product}’s <termsLink>Terms and Conditions</termsLink> and {company}'s <privacyLink>Privacy Notice</privacyLink>."
              id='sputnik.SignUpForm__dUVPpO'
              values={{
                company,
                privacyLink: (textValue) => (
                  <TextLink
                    href={BILL_PRIVACY_NOTICE}
                    target='_blank'
                  >
                    {textValue}
                  </TextLink>
                ),
                product,
                termsLink: (textValue) => (
                  <TextLink
                    href={BILL_TERMS_AND_CONDITIONS}
                    target='_blank'
                  >
                    {textValue}
                  </TextLink>
                ),
              }}
            />
          </div>
          <div
            className='recaptcha-wrapper'
            data-testid='recaptcha-wrapper'
          >
            <RecaptchaDisclaimer />
          </div>
          <TimeoutWarningModal pageName='signup' />
        </section>
      </>
    )
  }

  return (
    <div css={signupWrapperStyles}>
      {getFullForm()}
      <div className='submit-wrapper'>
        <FormButtons
          hideExit
          hidePrevious
          dataTestId='signup-'
          disableNext={!formIsValid || !verifyReCaptcha || isPersonal || submitting}
          handleNextClick={() => onNextClick(formValues)}
          showNextSpinner={submitting}
        />
      </div>
    </div>
  )
}

SignUpForm.propTypes = {
  onNextClick: func,
  submitting: bool,
  verifyReCaptcha: func,
}

export default SignUpForm
