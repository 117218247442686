import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormCheckboxInput, FormContext, FormTextInput } from '@divvy-web/skylab.form'
import { bool, func } from 'prop-types'
import { DropdownInput, MaskedFormInput } from '../../components'
import { AddressFormFields } from '../../components/AddressForm'
import FormElementWrapper from '../../components/FormInputs/FormElementWrapper'
import FormEmailInput from '../../components/FormInputs/FormEmailInput'
import PhoneNumberInput from '../../components/FormInputs/PhoneNumberInput'
import FullNameInput from '../../components/FormInputs/FullNameInput'
import PassportInfo from '../../components/PassportInfo'
import { getUSStates, normalizeSmartQuotesAndEmdashes } from '../../components/utils'
import GetCountryCodes from '../gql/GetCountryCodes.gql'
import { countriesMapper } from '../../utils/countryUtils'
import { passportToggled } from '../../utils/dataUtils'
import { formStyles } from '../styles/applicationPageSharedStyles'
import { MIGRATION } from '../../resources/constants'
import { authorizedSignerTitleOptions, authorizedSignerTitleOptionsArray } from './authorizedSignerFormUtils'

const AuthorizedSignerForm = ({ alwaysShowError, isReview, readOnly }) => {
  const bottomRef = useRef(null)
  const { data } = useQuery(GetCountryCodes)
  const { formValues, getFormValue, resetForm, setFormValue } = useContext(FormContext)
  const isUsingPassport = passportToggled(formValues)

  const usStatesOptions = getUSStates()
  const countryOptions = countriesMapper(data?.countryCodes)
  const [pageLoaded, setPageLoaded] = useState(false)

  const executeScrollToPassport = () => bottomRef.current?.scrollIntoView({ behavior: 'smooth' })

  const {
    adminEmail,
    adminFirstName,
    adminLastName,
    adminPhoneNumber,
    email,
    firstName,
    lastName,
    phoneNumber,
    preferredFullName,
    ssnDisplay,
    recordType,
  } = formValues

  const isAuthSignerNameTooLong = firstName?.length + lastName?.length > 21
  const showFullName = readOnly ? preferredFullName?.length > 0 && isAuthSignerNameTooLong : isAuthSignerNameTooLong
  const isMigration = recordType === MIGRATION

  const shouldShowFullNameTooltip =
    !isReview &&
    (!preferredFullName || preferredFullName?.length > 21 || !getFormValue('_savedSections')?.includes('AUTH_SIGNER'))

  const adminValuesEqual = useMemo(() => {
    return (
      adminFirstName === firstName &&
      adminLastName === lastName &&
      adminEmail === email &&
      adminPhoneNumber === phoneNumber
    )
  }, [adminFirstName, adminLastName, adminEmail, adminPhoneNumber, firstName, lastName, email, phoneNumber])

  const adminAutoFillToggled = !!getFormValue('_autoFillAdminInfoToggle')

  useEffect(() => {
    if (!isAuthSignerNameTooLong) setFormValue('preferredFullName', null)
  }, [isAuthSignerNameTooLong, setFormValue])

  useEffect(() => {
    if (readOnly && ssnDisplay.includes('*****')) {
      resetForm()
    }
  }, [ssnDisplay, readOnly, resetForm])

  useEffect(() => {
    if (!adminValuesEqual && adminAutoFillToggled) {
      setFormValue('_autoFillAdminInfoToggle', false)
    }
  }, [adminAutoFillToggled, adminValuesEqual, setFormValue])

  useEffect(() => {
    if (!pageLoaded && adminValuesEqual) {
      setFormValue('_autoFillAdminInfoToggle', true)
      setPageLoaded(true)
    }
  }, [pageLoaded, adminValuesEqual, setFormValue, setPageLoaded])

  useEffect(() => {
    if (!readOnly && isUsingPassport && !getFormValue('passportNumberDisplay') && !getFormValue('passportExpiration')) {
      executeScrollToPassport()
    }
  }, [isUsingPassport, readOnly, getFormValue])

  const toggleAutoFillAdminInfo = () => {
    setFormValue('_autoFillAdminInfoToggle', !adminAutoFillToggled)

    if (!adminAutoFillToggled) {
      setFormValue('firstName', getFormValue('adminFirstName'))
      setFormValue('lastName', getFormValue('adminLastName'))
      setFormValue('email', getFormValue('adminEmail'))
      setFormValue('phoneNumber', getFormValue('adminPhoneNumber'))
    }
  }

  const toggleSsn = () => {
    setFormValue('_passportToggle', !isUsingPassport)
    if (isUsingPassport) {
      setFormValue('passportNumber', '')
      setFormValue('passportNumberDisplay', '')
      setFormValue('passportCountry', '')
      setFormValue('passportExpiration', '')
    } else {
      setFormValue('ssnDisplay', '')
      setFormValue('ssn', '')
    }
  }

  const passportInformation = (
    <PassportInfo
      alwaysShowError={alwaysShowError}
      dataTestId='authorized-signer-form'
      pathName='authorizedSigner'
      readOnly={readOnly}
    />
  )

  return (
    <section css={(theme) => formStyles({ isReview, readOnly }, theme)}>
      <div className='auth-signer-form-field-wrapper'>
        {!readOnly && !isMigration && (
          <AutoFillAdminInfoToggle
            autoFillAdminDetails={adminAutoFillToggled}
            toggleAutoFillAdminInfo={toggleAutoFillAdminInfo}
          />
        )}
        <div className='field-row fs-unmask'>
          <div className='field-container'>
            <FormElementWrapper>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                className='fs-mask'
                dataTestId='authorized-signer-form-firstName'
                formFieldClassName='authorized-signer-first-name'
                label={
                  <FormattedMessage
                    defaultMessage='First name'
                    id='sputnik.AuthorizedSignerForm__pONqz8'
                  />
                }
                maxLength={30}
                name='firstName'
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='First name'
                    id='sputnik.AuthorizedSignerForm__pONqz8'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
          <div className='field-container'>
            <FormElementWrapper>
              <FormTextInput
                alwaysShowError={alwaysShowError}
                autoComplete='none'
                className='fs-mask'
                dataTestId='authorized-signer-form-lastName'
                formFieldClassName='authorized-signer-last-name'
                label={
                  <FormattedMessage
                    defaultMessage='Last name'
                    id='sputnik.AuthorizedSignerForm__txUL0F'
                  />
                }
                maxLength={30}
                name='lastName'
                normalize={normalizeSmartQuotesAndEmdashes}
                placeholder={
                  <FormattedMessage
                    defaultMessage='Last name'
                    id='sputnik.AuthorizedSignerForm__txUL0F'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
        </div>
        {showFullName && (
          <div className='field-row'>
            <div className='field-container-full'>
              <FullNameInput
                alwaysShowError={alwaysShowError}
                className='fs-mask'
                dataTestId='authorized-signer-form-preferred-full-name'
                formFieldClassName='authorized-signer-preferred-full-name'
                name='preferredFullName'
                preferredFullName={preferredFullName}
                readOnly={readOnly}
                showTooltipAtStart={shouldShowFullNameTooltip}
              />
            </div>
          </div>
        )}
        <div className='field-row fs-unmask'>
          <div className='field-container'>
            <FormElementWrapper>
              <DropdownInput
                isSearchable
                alwaysShowError={readOnly}
                className='fs-mask'
                dataTestId='authorized-signer-form-title'
                items={authorizedSignerTitleOptions}
                label={
                  <FormattedMessage
                    defaultMessage='Title'
                    id='sputnik.AuthorizedSignerForm__9a9+ww'
                  />
                }
                name='title'
                placeholder={
                  <FormattedMessage
                    defaultMessage='Title'
                    id='sputnik.AuthorizedSignerForm__9a9+ww'
                  />
                }
                readOnly={readOnly}
              />
            </FormElementWrapper>
          </div>
          <div className='field-container'>
            <MaskedFormInput
              isDOB
              alwaysShowError={alwaysShowError}
              className='authorized-signer-dob fs-mask'
              dataTestId='authorized-signer-form-dob'
              inputMode='numeric'
              label={
                <FormattedMessage
                  defaultMessage='Date of birth'
                  id='sputnik.AuthorizedSignerForm__GFTdXw'
                />
              }
              maxLength={10}
              name='dobDisplay'
              pathName='authorizedSigner'
              placeholder={
                <FormattedMessage
                  defaultMessage='Date of birth'
                  id='sputnik.AuthorizedSignerForm__GFTdXw'
                />
              }
              readOnly={readOnly}
            />
          </div>
        </div>
        <div className='field-row'>
          <div className='field-container fs-unmask'>
            <FormEmailInput
              alwaysShowError={alwaysShowError}
              autoComplete='on'
              className='authorized-signer-email fs-mask'
              dataTestId='authorized-signer-form-workEmail'
              label={
                <FormattedMessage
                  defaultMessage='Work email'
                  id='sputnik.AuthorizedSignerForm__HHRIuR'
                />
              }
              name='email'
              placeholder={
                <FormattedMessage
                  defaultMessage='Work email'
                  id='sputnik.AuthorizedSignerForm__HHRIuR'
                />
              }
              readOnly={readOnly}
              type='email'
            />
          </div>
          <div className='field-container'>
            <PhoneNumberInput
              alwaysShowError={alwaysShowError}
              inputId='phoneNumber'
              label={
                <FormattedMessage
                  defaultMessage='Mobile phone number'
                  id='sputnik.AuthorizedSignerForm__L0ijz8'
                />
              }
              readOnly={readOnly}
            />
          </div>
        </div>
        <AddressFormFields
          allowNonUsAddress
          alwaysShowError={alwaysShowError}
          countryOptions={countryOptions}
          label={
            <FormattedMessage
              defaultMessage='Residential address'
              id='sputnik.AuthorizedSignerForm__4iXhxD'
            />
          }
          labelLine2={
            <FormattedMessage
              defaultMessage='Residential address line 2'
              id='sputnik.AuthorizedSignerForm__1WWwX6'
            />
          }
          pageName='address'
          placeholder={
            <FormattedMessage
              defaultMessage='Enter street address'
              id='sputnik.AuthorizedSignerForm__gU60u1'
            />
          }
          readOnly={readOnly}
          stateOptions={usStatesOptions}
        />
        <div className='field-row'>
          <div className='field-container fs-unmask'>
            {!isUsingPassport && (
              <MaskedFormInput
                alwaysShowError={alwaysShowError}
                className='authorized-signer-ssn fs-mask'
                dataTestId='authorized-signer-form-ssn'
                inputMode='numeric'
                label={
                  <FormattedMessage
                    defaultMessage='Social security number'
                    id='sputnik.AuthorizedSignerForm__GQ9iTd'
                  />
                }
                maxLength={11}
                name='ssnDisplay'
                pathName='authorizedSigner'
                placeholder='Social security number'
                readOnly={readOnly}
              />
            )}
            {readOnly || (
              <IdTypeToggle
                isUsingPassport={isUsingPassport}
                toggleSsn={toggleSsn}
              />
            )}
          </div>
        </div>
        {isUsingPassport && passportInformation}
      </div>
      <div ref={bottomRef} />
    </section>
  )
}

const IdTypeToggle = ({ isUsingPassport, toggleSsn }) => (
  <FormCheckboxInput
    isSmall
    autoComplete='none'
    checkboxLabel={
      <FormattedMessage
        defaultMessage='Non US citizen and does not have SSN.'
        id='sputnik.AuthorizedSignerForm__h5FGaJ'
      />
    }
    checked={isUsingPassport}
    className='passport-toggle fs-unmask'
    dataTestId='authorized-signer-form-passportToggle'
    name='_passportToggle'
    onChange={toggleSsn}
  />
)

const AutoFillAdminInfoToggle = ({ autoFillAdminDetails, toggleAutoFillAdminInfo }) => {
  return (
    <FormCheckboxInput
      isSmall
      autoComplete='none'
      checkboxLabel={
        <FormattedMessage
          defaultMessage="Auto fill with your admin's information"
          id='sputnik.AuthorizedSignerForm__fwKNQU'
        />
      }
      checked={autoFillAdminDetails}
      className='autofill-admin-info-toggle fs-unmask'
      dataTestId='authorized-signer-admin-info-Toggle'
      name='_autoFillAdminInfoToggle'
      onChange={toggleAutoFillAdminInfo}
    />
  )
}

AuthorizedSignerForm.defaultProps = {
  alwaysShowError: false,
  isReview: false,
  readOnly: false,
}
AuthorizedSignerForm.propTypes = {
  alwaysShowError: bool,
  isReview: bool,
  readOnly: bool,
}
IdTypeToggle.propTypes = {
  isUsingPassport: bool,
  toggleSsn: func.isRequired,
}
AutoFillAdminInfoToggle.propTypes = {
  autoFillAdminDetails: bool,
  toggleAutoFillAdminInfo: func.isRequired,
}

export default AuthorizedSignerForm
