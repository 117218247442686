import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCanary } from '@divvy-web/canary'
import { FormattedMessage } from '@divvy-web/i18n'
import { TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import GetDecisions from '../../pages/gql/GetDecisions.gql'
import GetApplicationStatus from '../../pages/gql/GetApplicationStatus.gql'
import useDivvyUUID from '../../hooks/useDivvyUUID'
import ProcessingApplication from '../ProcessingApplication/'
import { PATHNAME_CONSTS } from '../../resources/constants'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'

const CREDIT_DECISION_POLLING_MAX_TIMEOUT = window.__DivvyEnvironment.PROCESSING_MAX_TIMEOUT || 500
const STATUS_POLLING_MAX_TIMEOUT = 120000
const POLLING_RATE = 3000

const calculateStatusTimeoutRemaining = (pollingForStatusSince) =>
  STATUS_POLLING_MAX_TIMEOUT - (new Date().getTime() - pollingForStatusSince)

const RocketAnimation = () => {
  /* make sure we have a token before we start polling */
  useApplicantTokenOnMount()
  const navigate = useNavigate()
  const showToast = useToast()
  const { pathname, search: urlSearch } = useLocation()
  const { appId } = useParams()
  const showSecuredLine = useCanary('show-secured-line')
  const appPath = `/app/${appId}/section/processed`
  const [search, setSearch] = useSearchParams()
  const pollingForStatusSince = search.get('pollingForStatusSince')
    ? parseInt(search.get('pollingForStatusSince'))
    : null

  const [companyDivvyUuid] = useDivvyUUID()
  const [creditDecisionTimer, setCreditDecisionTimer] = useState(0)
  const [statusTimer, setStatusTimer] = useState(0)

  const {
    data: decisionsData,
    startPolling: startPollingForCreditDecision,
    stopPolling: stopPollingForCreditDecision,
  } = useQuery(GetDecisions, {
    fetchPolicy: 'no-cache',
    skip: !companyDivvyUuid || pollingForStatusSince,
    variables: { companyId: companyDivvyUuid },
  })

  const {
    data: statusData,
    startPolling: startPollingForStatus,
    stopPolling: stopPollingForStatus,
  } = useQuery(GetApplicationStatus, {
    fetchPolicy: 'no-cache',
    skip: !pollingForStatusSince,
    variables: { creditApplicationId: appId },
  })

  const { creditApplication } = statusData || {}
  const isPollingAfterOfferAcceptance = search.get('isPollingAfterOfferAcceptance') === 'true'

  const handleSubmissionError = useCallback(() => {
    stopPollingForStatus()
    window.sessionStorage.removeItem('submissionPendingUrl')
    navigate(`/app/${appId}/section/submission-error`)
  }, [appId, navigate, stopPollingForStatus])

  const handleSubmissionSuccess = useCallback(() => {
    stopPollingForStatus()
    if (search.get('isMigration') === 'true') {
      navigate(`/app/${appId}/section/success`, { replace: true })
      window.sessionStorage.removeItem('submissionPendingUrl')
    } else {
      setSearch({})
    }
  }, [appId, navigate, search, setSearch, stopPollingForStatus])

  const decisions = decisionsData?.decisions
  const frozenAuthorizedSigner = decisionsData?.frozenAuthorizedSigner
  const underwritingDeclined = decisionsData?.underwritingDeclined
  const finishedPollingForCreditDecision =
    creditDecisionTimer >= CREDIT_DECISION_POLLING_MAX_TIMEOUT || decisions?.length > 0 || underwritingDeclined

  // save url to redirect back to it in case user leaves this page
  useEffect(() => {
    if (urlSearch.includes('pollingForStatusSince')) {
      window.sessionStorage.setItem('submissionPendingUrl', pathname + urlSearch)
    }
  }, [pathname, urlSearch])

  // handle polling for status
  useEffect(() => {
    if (pollingForStatusSince) {
      startPollingForStatus(POLLING_RATE)
      if (statusTimer >= calculateStatusTimeoutRemaining(pollingForStatusSince)) {
        handleSubmissionError()
      } else {
        setTimeout(() => setStatusTimer(statusTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [handleSubmissionError, pollingForStatusSince, startPollingForStatus, statusTimer])

  const originalStatus = search.get('originalStatus')
  useEffect(() => {
    if (creditApplication?.status && originalStatus && creditApplication?.status !== originalStatus) {
      handleSubmissionSuccess()
    }

    /* when an offer is accepted and marked as APPROVED, navigate to the dashboard */
    if (creditApplication?.status === 'APPROVED' && creditApplication?.appVersion === 2) {
      stopPollingForStatus()
      navigate(PATHNAME_CONSTS.DASHBOARD_PATH, { replace: true })
      showToast(
        TOAST_TYPE_SUCCESS,
        <FormattedMessage
          defaultMessage='Offer accepted'
          id='sputnik.RocketAnimation__IB0Vm6'
        />,
        {
          autoHideDelay: 5000,
        },
      )
    }
  }, [
    creditApplication?.appVersion,
    creditApplication?.status,
    handleSubmissionSuccess,
    navigate,
    originalStatus,
    showToast,
    stopPollingForStatus,
  ])

  // handle polling for credit decision
  useEffect(() => {
    if (!pollingForStatusSince) {
      startPollingForCreditDecision(POLLING_RATE)
      if (creditDecisionTimer >= CREDIT_DECISION_POLLING_MAX_TIMEOUT) {
        stopPollingForCreditDecision()
      } else {
        setTimeout(() => setCreditDecisionTimer(creditDecisionTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [creditDecisionTimer, pollingForStatusSince, startPollingForCreditDecision, stopPollingForCreditDecision])

  useEffect(() => {
    if (finishedPollingForCreditDecision) {
      window.sessionStorage.removeItem('submissionPendingUrl')
      if (frozenAuthorizedSigner) {
        navigate(`${appPath}${PATHNAME_CONSTS.FROZEN_CREDIT_PATH}`, { replace: true })
      } else if (underwritingDeclined) {
        navigate(`${appPath}${PATHNAME_CONSTS.DECLINED_PATH}`, { replace: true })
      } else if (!decisions || decisions.length === 0 || decisions.length > 3) {
        navigate(`${appPath}${PATHNAME_CONSTS.MANUAL_REVIEW_PATH}`, { replace: false })
      } else if (showSecuredLine && decisions.length >= 2) {
        navigate(`${appPath}${PATHNAME_CONSTS.MULTI_CREDIT_LINE_OFFER_PATH}`, { replace: false })
      } else if (!showSecuredLine && decisions.length === 2) {
        navigate(`${appPath}${PATHNAME_CONSTS.DUAL_OFFER_PATH}`, { replace: false })
      } else if (showSecuredLine) {
        navigate(`${appPath}${PATHNAME_CONSTS.SINGLE_CREDIT_LINE_OFFER_PATH}`, { replace: false })
      } else {
        navigate(`${appPath}${PATHNAME_CONSTS.PRE_APPROVED_PATH}`, { replace: true })
      }
    }
  }, [
    appPath,
    decisions,
    finishedPollingForCreditDecision,
    frozenAuthorizedSigner,
    navigate,
    showSecuredLine,
    underwritingDeclined,
  ])

  return <ProcessingApplication isPollingAfterOfferAcceptance={isPollingAfterOfferAcceptance} />
}

export default RocketAnimation
