// include team's acronyms in the description for easier search
export default [
  {
    description: 'when enabled, shorten the time that the timeout warning modal is displayed and active to 15 seconds',
    name: 'reduceAccessTokenTimeout',
    value: false,
  },
  {
    description: 'when enabled, enables new authentication logic',
    name: 'useNewAuth',
    value: false,
  },
  {
    description: 'when enabled, enables advanced onboarding return flow logic',
    name: 'advanced-onboarding-return-flow',
    value: false,
  },
  {
    description:
      'COM: shows secured line flow which includes UW accept offer pages, and new review offer tile, and new good fit page',
    name: 'show-secured-line',
    value: false,
  },
  {
    description: 'COM: shows the AP secured line flow for application (this is separate from show-secured-line)',
    name: 'ap-secured-line',
    value: false,
  },
  {
    description: 'COM: when enabled, sets the appVersion for an application to 2',
    name: 'state-based-disclosures',
    value: false,
  },
]
